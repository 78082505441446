<template>
  <div class="c-participation__header"  v-if="participation">
    <div class="c-participation__participant">
      <div class="c-participation__bib" v-if="participation.bib">
        {{participation.bib}}
      </div>
      <div class="c-participation__name">
        <component :is="'participant-' + participation.participantType" :participation="participation"/>
      </div>
      <div class="c-participation__cat" v-if="participation.bib">
        <div v-if="participant.dob">
          <span class="c-participation__cat-label">{{$t('dob.short')}}</span>
          {{ getDob() }}
        </div>
        <div>
          <span class="c-participation__cat-label">{{$t('type')}}</span>
          {{$t('exercise.type.' + exercise.exerciseTypeId)}}
          {{configuration.passes > 1 ? ' -  pass ' + pass :''}}
        </div>
        <div><span class="c-participation__cat-label">{{$t('cat')}}</span> {{category.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import find from 'lodash/find';

  import _participant from 'client/lib/participant.js'

  import moment from 'moment'

  export default {
    name: "exerciseHeader",
    components: {
      "participant-clubMember": require('./participantClubMember.vue').default,
      "participant-group": require('./participantGroup.vue').default,
    },
    data: function() {
      return {
        participation: undefined,
        participant: undefined,
        club: undefined,
        category: undefined,
      }
    },
    computed: {
      exercise: function() {
        return this.$store.state.exercise.exercise;
      },
      pass: function() {
        return this.$store.state.exercise.pass;
      },
      configuration: function() {
        return this.$store.state.exercise.exerciseConfiguration;
      },
    },
    created: function() {
      this.participation = find(this.$store.state.participations.items, item => { return item.id === this.exercise.participationId; });
      if (this.participation) {
        this.participant = _participant.getParticipant(this.participation)
        this.club = _participant.getClub(this.participant)
        this.category = _participant.getCategory(this.participation)
      }
    },
    methods: {
      getDob: function() {
        return moment(this.participant.dob).format('YYYY-MM-DD')
      }
    }
  };
</script>

<style scoped>
</style>
